export const LandingData = {
  moes: {
    heroBackgroundImage: "/moes/hero.jpg",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    showBgOnMobile: true,
    stepTitle:
      "",
    stepDescription: `<h1>AARP MEMBERS SAVE 10% AT MOE'S SOUTHWEST GRILL EVERY DAY! ​ </h1> </br> <p class="landing-process">Sign up for a Moe Rewards account or sign into your Moe Rewards account below and link your account with your AARP membership. ​ </p> </br> <p class="landing-completed-message">Once you have completed this process, you will be ready to save with Moe’s Southwest Grill. The discount will be placed in your Moe Rewards account daily and there is no need to link your account again.​ </p>`,
    hideSteps: true,
    downloadSection: {
      text: "download the Moe Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affiliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beginning",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  moestmobile: {
    heroBackgroundImage: "/moes/hero.jpg",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    showBgOnMobile: true,
    showSignInForm: true,
    pageTitle: "BUY A BURRITO OR BOWL, GET ONE FREE!​​",
    infoText: "Sign up or sign into your Moe's Rewards account below and the offer will be automatically applied to your account.",
    additionalInfoText: "Tax and fees excluded. Discount applies to item of equal or lesser value, exclusions apply. See below for details.",
    stepTitle:
      "",
    stepDescription: `<h1>AARP MEMBERS SAVE 10% AT MOE'S SOUTHWEST GRILL EVERY DAY! ​ </h1> </br> <p class="landing-process">Sign up for a Moe Rewards account or sign into your Moe Rewards account below and link your account with your AARP membership. ​ </p> </br> <p class="landing-completed-message">Once you have completed this process, you will be ready to save with Moe’s Southwest Grill. The discount will be placed in your Moe Rewards account daily and there is no need to link your account again.​ </p>`,
    hideSteps: true,
    downloadSection: {
      text: "download the Moe Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affiliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
      disclaimerText:
      `<div class="container disclaimer-additional"><p>
Offer valid for Moe Rewards Members at participating locations 11/12-11/17/24. Buy one Burrito or Bowl and receive a second Burrito or Bowl on us (excluding taxes and fees). Discount applies to item of lowest value. Single use. Must apply Reward at check out. Not valid with any other offer or reward, or third-party delivery. Service fee applies to online and in app orders. Other fees and taxes may apply, see checkout for details. Void where prohibited.​</p><br /><p>©2025 Moe’s Franchisor SPV LLC. All rights reserved. T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG​</p></div>`,
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beginning",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  aamuttggenroll: {
    pageTitle: "Join Auntie Anne’s Rewards and earn 250 GG points!",
    infoText: "Redeem your GG points in raffles for a chance to win prizes.",
    heroBackgroundImage: "/auntieannes/gg-points-hero.png",
    showBgOnMobile: true,
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "AARP Logo",
    heroDescription:
      "Enjoy 10% off your check* by linking your AARP membership to a Moe Rewards account.",
    heroSignUpSubtitle: "Not a Moe Rewards member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "Already a Moe Rewards member?",
    heroLinkAccountButton: "Link Your Account",
    stepTitle:
      "In just 3 easy steps, you'll be on your way to enjoying AARP member benefits and Moe Rewards!",
    step1: {
      showSignUpLink: true,
      imageURL: "/moes/step1.png",
      imageAlt: "Join Moe Rewards",
      title: "Join Moe Rewards",
      line1: {
        postlinkText: "for a Moe Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/moes/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/moes/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-restaurant, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Moe Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: true,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: true,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText: "",
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beginning",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    showSpotlightSection: true,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  aamuttgg5off25: {
    offerExpired: false,
    showSignInForm: true,
    showBgOnMobile: true,
    pageTitle: "Save $5 off any order of $25 or more!",
    infoText: "Sign up or sign into your Auntie Anne's Rewards account below and the offer will be automatically applied to your account.",
    additionalInfoText: "Taxes and fees excluded. Single use. See below for details.​",
    heroBackgroundImage: "/auntieannes/5-off-25-hero.png",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "AARP Logo",
    heroDescription:
      "Enjoy 10% off your check* by linking your AARP membership to a Moe Rewards account.",
    heroSignUpSubtitle: "Not a Moe Rewards member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "Already a Moe Rewards member?",
    heroLinkAccountButton: "Link Your Account",
    stepTitle:
      "In just 3 easy steps, you'll be on your way to enjoying AARP member benefits and Moe Rewards!",
    step1: {
      showSignUpLink: true,
      imageURL: "/moes/step1.png",
      imageAlt: "Join Moe Rewards",
      title: "Join Moe Rewards",
      line1: {
        postlinkText: "for a Moe Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/moes/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/moes/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-restaurant, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Moe Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: true,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: true,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText:
      "Receive $5 off your purchase of $25+ (excluding taxes and fees) at participating locations through February 23, 2025. Must apply Reward at checkout through Rewards Program. Single use. Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited. ​©2025 Auntie Anne’s Franchisor SPV LLC. All rights reserved.",
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beginning",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    showSpotlightSection: true,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  aamuttggbogo: {
    showBgOnMobile: true,
    offerExpired: false,
    showSignInForm: true,
    pageTitle: "BUY A PRETZEL ITEM, GET ONE FREE!",
    infoText: "Sign up or sign into your Auntie Anne's Rewards account below and the offer will be automatically applied to your account.",
    additionalInfoText: "Discount applies to item of equal or lesser value, exclusions apply. Tax and fees excluded. Single use. See below for details.",
    heroBackgroundImage: "/auntieannes/bogo-hero.png",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "AARP Logo",
    heroDescription:
      "Enjoy 10% off your check* by linking your AARP membership to a Moe Rewards account.",
    heroSignUpSubtitle: "Not a Moe Rewards member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "Already a Moe Rewards member?",
    heroLinkAccountButton: "Link Your Account",
    stepTitle:
      "In just 3 easy steps, you'll be on your way to enjoying AARP member benefits and Moe Rewards!",
    step1: {
      showSignUpLink: true,
      imageURL: "/moes/step1.png",
      imageAlt: "Join Moe Rewards",
      title: "Join Moe Rewards",
      line1: {
        postlinkText: "for a Moe Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/moes/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/moes/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-restaurant, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Moe Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: true,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: true,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText:
      "Buy one pretzel item, get one pretzel item free at participating locations is valid for 7 days after issue date. Taxes and fees excluded. Discount applies to item of equal or lesser value. All pretzel buckets, dips, catering items, snack packs + protein items (pretzel dog, cheese pretzel, jalapeño & cheese pretzel dog, mini pretzel dogs, and pepperoni pretzel nuggets) qualify for purchase but are excluded from receiving item on us. Single use. Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited. ©2025 Auntie Anne’s Franchisor SPV LLC. All rights reserved.",
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beginning",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    showSpotlightSection: true,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  aamuttgg5off10: {
    showBgOnMobile: true,
    offerExpired: false,
    showSignInForm: true,
    pageTitle: "SAVE $5 OFF ANY ORDER OF $10 OR MORE!",
    infoText: "Sign up or sign into your Auntie Anne's Rewards account below and the offer will be automatically applied to your account.",
    additionalInfoText: "Taxes and fees excluded. Single use. See below for details.​",
    heroBackgroundImage: "/auntieannes/5-off-10-hero.png",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "AARP Logo",
    heroDescription:
      "Enjoy 10% off your check* by linking your AARP membership to a Moe Rewards account.",
    heroSignUpSubtitle: "Not a Moe Rewards member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "Already a Moe Rewards member?",
    heroLinkAccountButton: "Link Your Account",
    stepTitle:
      "In just 3 easy steps, you'll be on your way to enjoying AARP member benefits and Moe Rewards!",
    step1: {
      showSignUpLink: true,
      imageURL: "/moes/step1.png",
      imageAlt: "Join Moe Rewards",
      title: "Join Moe Rewards",
      line1: {
        postlinkText: "for a Moe Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/moes/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/moes/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-restaurant, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Moe Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: true,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: true,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText:
      "The $5 off your purchase of $10+ (excluding taxes and fees) at participating locations is valid for 7 days after issue date. Must apply Reward at checkout through Rewards Program. Single use.  Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited. ​©2025 Auntie Anne’s Franchisor SPV LLC. All rights reserved.",
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beginning",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    showSpotlightSection: true,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  aamuttgg10off30: {
    offerExpired: false,
    showSignInForm: true,
    showBgOnMobile: true,
    pageTitle: "SAVE $10 OFF ANY ORDER OF $30 OR MORE!",
    infoText: "Sign up or sign into your Auntie Anne's Rewards account below and the offer will be automatically applied to your account.",
    additionalInfoText: "Taxes and fees excluded. Single use. See below for details.​",
    heroBackgroundImage: "/auntieannes/10-off-30-hero.png",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "AARP Logo",
    heroDescription:
      "Enjoy 10% off your check* by linking your AARP membership to a Moe Rewards account.",
    heroSignUpSubtitle: "Not a Moe Rewards member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "Already a Moe Rewards member?",
    heroLinkAccountButton: "Link Your Account",
    stepTitle:
      "In just 3 easy steps, you'll be on your way to enjoying AARP member benefits and Moe Rewards!",
    step1: {
      showSignUpLink: true,
      imageURL: "/moes/step1.png",
      imageAlt: "Join Moe Rewards",
      title: "Join Moe Rewards",
      line1: {
        postlinkText: "for a Moe Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/moes/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/moes/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-restaurant, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Moe Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: true,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: true,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText:
      "The $10 off your purchase of $30+ (excluding taxes and fees) at participating locations is valid for 7 days after issue date. Must apply Reward at checkout through Rewards Program. Single use. Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited. ​©2025 Auntie Anne’s Franchisor SPV LLC. All rights reserved.",
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beginning",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    showSpotlightSection: true,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  aatmoaug27: {
    showSignInForm: true,
    pageTitle: "BUY A PRETZEL ITEM, GET ONE FREE!​",
    infoText: "Sign up or sign into your Auntie Anne's Rewards account below and the offer will be automatically applied to your account.",
    additionalInfoText: "Tax and fees excluded. Discount applies to item of equal or lesser value, exclusions apply. See below for details.",
    heroBackgroundImage: "/auntieannes/bogo-hero.png",
    showBgOnMobile: true,
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "AARP Logo",
    heroDescription:
      "Enjoy 10% off your check* by linking your AARP membership to a Moe Rewards account.",
    heroSignUpSubtitle: "Not a Moe Rewards member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "Already a Moe Rewards member?",
    heroLinkAccountButton: "Link Your Account",
    stepTitle:
      "In just 3 easy steps, you'll be on your way to enjoying AARP member benefits and Moe Rewards!",
    step1: {
      showSignUpLink: true,
      imageURL: "/moes/step1.png",
      imageAlt: "Join Moe Rewards",
      title: "Join Moe Rewards",
      line1: {
        postlinkText: "for a Moe Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/moes/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/moes/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-restaurant, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Moe Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: true,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: true,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText:
      "The buy one, get one pretzel item free offer is valid August 27, 2025 through August 30, 2025, at 11:59 p.m. ET, or close of business. Taxes and fees excluded. Discount applies to item of equal or lesser value. All pretzel buckets, catering items, snack packs + protein items (pretzel dog, cheese pretzel, jalapeño & cheese pretzel dog, mini pretzel dogs, pepperoni pretzels and pepperoni pretzel nuggets) qualify for purchase but are excluded from receiving item free. Limit 1 offer/person/loyalty account. Not valid with any other promotion. No substitutions. Service fee applies to online, in-app and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void if altered, transferred, reproduced, exchanged, sold, purchased or where restricted by law. Not valid in PR or USVI. Excludes Auntie Anne’s/SUBWAY® locations and most travel plazas and airport locations. Price may vary; cash value 1/100 of 1 cent.TM/©2025 Auntie Anne’s Franchisor SPV LLC. All rights reserved. T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG​",
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beginning",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    showSpotlightSection: true,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  aatmobile: {
    showSignInForm: true,
    pageTitle: "BUY A PRETZEL ITEM, GET ONE FREE!​",
    infoText: "Sign up or sign into your Auntie Anne's Rewards account below and the offer will be automatically applied to your account.",
    additionalInfoText: "Tax and fees excluded. Discount applies to item of equal or lesser value, exclusions apply. See below for details.",
    heroBackgroundImage: "/auntieannes/bogo-hero.png",
    showBgOnMobile: true,
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "AARP Logo",
    heroDescription:
      "Enjoy 10% off your check* by linking your AARP membership to a Moe Rewards account.",
    heroSignUpSubtitle: "Not a Moe Rewards member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "Already a Moe Rewards member?",
    heroLinkAccountButton: "Link Your Account",
    stepTitle:
      "In just 3 easy steps, you'll be on your way to enjoying AARP member benefits and Moe Rewards!",
    step1: {
      showSignUpLink: true,
      imageURL: "/moes/step1.png",
      imageAlt: "Join Moe Rewards",
      title: "Join Moe Rewards",
      line1: {
        postlinkText: "for a Moe Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/moes/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/moes/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-restaurant, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Moe Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: true,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: true,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText:
      "The buy one, get one pretzel item free offer is valid October 22, 2025 through October 25, 2025, at 11:59 p.m. ET, or close of business. Taxes and fees excluded. Discount applies to item of equal or lesser value. All pretzel buckets, catering items, snack packs + protein items (pretzel dog, cheese pretzel, jalapeño & cheese pretzel dog, mini pretzel dogs, pepperoni pretzels and pepperoni pretzel nuggets) qualify for purchase but are excluded from receiving item free. Limit 1 offer/person/loyalty account. Not valid with any other promotion. No substitutions. Service fee applies to online, in-app and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void if altered, transferred, reproduced, exchanged, sold, purchased or where restricted by law. Not valid in PR or USVI. Excludes Auntie Anne’s/SUBWAY® locations and most travel plazas and airport locations. Price may vary; cash value 1/100 of 1 cent.TM/©2025 Auntie Anne’s Franchisor SPV LLC. All rights reserved. T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG​",
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beginning",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    showSpotlightSection: true,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  schlotzskystmobile: {
    showSignInForm: true,
    pageTitle: "ENJOY A FREE SMALL SANDWICH WITH THE PURCHASE OF A REGULAR DRINK​",
    infoText: "Sign up or sign into your Schlotzsky’s Rewards account below and the offer will be automatically applied to your account.​",
    additionalInfoText: "Tax not included. Exclusions apply. See below for details.​",
    heroBackgroundImage: "/schlotzskys/tmobile-hero-09-10-24.jpg",
    showBgOnMobile: true,
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    showButtonListSection: true,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: true,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText:
      "The free Small Sandwich with purchase of a Regular Drink offer is valid September 10, 2025 through September 16, 2025, at 11:59 p.m. ET, or close of business. Taxes and fees excluded. No substitutions, excludes add-ons, extra toppings or gluten free. Reward can be redeemed using a valid Schlotzsky’s Rewards account at participating locations, in store, on Schlotzskys.com, or in the Schlotzsky’s app. Limit 1/person/loyalty account. Must apply Reward at checkout. Not valid with any other offer or reward, or third party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.​",
    disclaimerTextAdditional: `<div class="container disclaimer-additional" style="padding-top: 10px;"><p style="padding-bottom: 10px;">
T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG.​</p> <p>© 2025 The Coca-Cola Company.</p></div>`,
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beginning",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    showSpotlightSection: true,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  schlotzskys: {
    heroBackgroundImage: "/schlotzskys/hero.jpg",
    showBgOnMobile: true,
    stepTitle:
      "",
    stepDescription: `<h1>AARP members save 10% at Schlotzsky's every day! </h1> </br> <p class="landing-process">Sign up for a Schlotzsky’s Rewards account or sign into your Schlotzsky's Rewards account below and link your account with your AARP membership. </p> </br> <p class="landing-completed-message">Once you have completed this process, you will be ready to save with Schlotzsky’s. The discount will be placed in your Schlotzsky’s Rewards account daily and there is no need to link your account again.​ </p>`,
    hideSteps: true,
    showButtonListSection: false,
    showBenefitsSection: false,
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Schlotzsky's Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering, gift cards or third-party delivery orders.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affilliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Schlotzsky's Rewards App",
    spotlightDescription: [
      {
        text: "",
      },
    ],
    spotlightImageURL: "",
    spotlightImageAlt:
      "",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  mcalisters: {
    heroBackgroundImage: "/fetchmca/hero.jpg",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    stepTitle:
      "",
    stepDescription: `<h1>AARP members save 10% at Mcalisters's Deli every day! </h1> </br> <p class="landing-process">To receive this discount, sign up for a McAlister’s Rewards account or sign into your McAlister’s Rewards account below. Then link your account with your AARP membership. ​</p> </br> <p class="landing-completed-message">Once you have completed this process, the 10% discount reward will be placed in your McAlister’s Rewards account daily. You will need to show the reward in-store or apply the reward for online orders.​</p>`,
    showBgOnMobile: true,
    hideSteps: true,
    step1: {
      showSignUpLink: true,
      imageURL: "/mcalisters/step1.png",
      imageAlt: "Join McAlister's Rewards",
      title: "Join McAlister's Rewards",
      line1: {
        postlinkText: "for a McAlister's Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/mcalisters/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/mcalisters/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-restaurant, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://protect-us.mimecast.com/s/aDZ_CwpRkMUoBZYjcVbW5n?domain=appsec.aarp.org",
      target: "_blank",
    },
    downloadSection: {
      text: "download the McAlister's Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "",
    signUpSubtitle: "",
    signUpButton: "",
    linkAccountSubtitle: "",
    linkAccountButton: "",
    joinSubtitle: "",
    joinButton: {
      text: "",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "McAlister's Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for McAlister's Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering, gift cards or third-party delivery orders.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affiliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "Free Tea",
        title: "Free Tea",
        description:
          "Sign up for McAlister's Rewards and enjoy a tea on us as a welcome.",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Birthday Treat",
        title: "Birthday Treat",
        description: "It's your day to be celebrated. Enjoy a cookie on us!",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "McAlister's Rewards App",
    spotlightDescription: [
      {
        text: "Download the McAlister's Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite McAlister's Deli.",
      },
    ],
    spotlightImageURL: "/mcalisters/spotlight.jpg",
    spotlightImageAlt:
      "Download the McAlister's Rewards app for iOS or Android",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  mcalisterstmobile: {
    showSignInForm: true,
    pageTitle: "T-MOBILE TUESDAYS EXCLUSIVE OFFER: $5 OFF AN ENTREE​",
    infoText: "Sign up or sign into your McAlister's Rewards account below and the offer will be automatically applied to your account.​",
    additionalInfoText: "Tax and fees excluded. Exclusions apply. See below for details.​",
    heroBackgroundImage: "/mcalisters/herotmobile.jpg",
    showBgOnMobile: true,
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    showButtonListSection: true,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: true,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText: `
      <p>The $5 off an Entree (Choose 2, Sandwiches, Salads, Spuds, Shareables or Bowls of Soup) Reward is valid September 24, 2025 through September 27, 2025, at 11:59 p.m. ET or close of business. Taxes and fees excluded. No substitutions, excludes Kids Meals and alcohol. Reward must be redeemed using a valid McAlister’s Rewards account at participating locations, in store, online at McAlistersDeli.com, or in the McAlister’s Deli app. Limit 1/person/loyalty account. Must apply Reward at checkout. Not valid with any other offer or reward, or third party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.</p><br>
      <p>T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG.</p><br>
      <p>DR PEPPER is a registered trademark of Dr Pepper/Seven Up, Inc. ©2025 Dr Pepper/Seven Up, Inc.</p><br>
      <p>TM/© McAlister’s Franchisor SPV LLC.</p>`,
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Your Check",
        description: "Enjoy 10% off your check each day*.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beginning",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    showSpotlightSection: true,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  cinnabontmobile: {
    showSignInForm: true,
    pageTitle: "BUY ONE BAKED GOOD, GET ONE FREE",
    infoText: "Sign up or sign into your Cinnabon Rewards account below and the offer will be automatically applied to your account.​",
    additionalInfoText: "Tax and fees excluded. Discount applies to item of equal or lesser value, exclusions apply. See below for details.​",
    heroBackgroundImage: "/cinnabon/herotmobile.jpg",
    showBgOnMobile: true,
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    showButtonListSection: true,
    linkTitle: "",
    signUpSubtitle: "",
    signUpButton: "",
    linkAccountSubtitle: "",
    linkAccountButton: "",
    joinSubtitle: "",
    joinButton: {
      text: "",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: true,
    benefitsTitle: "",
    rewardsDisclaimer:
      "",
    disclaimerText:
      "The buy one baked good (Classic Roll, MiniBon, 4-count BonBites or Center of the Roll), get one baked good item free offer is valid October 15, 2025 through October 20, 2025, at 11:59 p.m. ET, or close of business at participating Cinnabon bakery locations. At Cinnabon co-branded stores and Dual Branded Bakeries, customers must place an order for pick-up only within Cinnabon app; in-store purchases not applicable for these locations. Taxes and fees excluded. Discount applies to item of equal or lesser value. Limit 1 offer/person/loyalty account. Not valid with any other promotion. No substitutions. Service fee applies to online, in-app and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void if altered, transferred, reproduced, exchanged, sold, purchased or where restricted by law. Not valid in PR or USVI. Price may vary; cash value 1/100 of 1 cent.TM/©2025 Cinnabon Franchisor SPV LLC. All rights reserved. T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG​",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description:
          "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: true,
    spotlightID: "",
    spotlightTitle: "",
    spotlightDescription: [
      {
        text: "",
      },
    ],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  auntieannes: {
    heroBackgroundImage: "/auntieannes/hero.png",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    showBgOnMobile: true,
    hideSteps: true,
    heroDescription:
      "Enjoy 10% off your check* by linking your AARP membership to your Auntie Anne's Rewards account.",
    heroSignUpSubtitle: "Not an Auntie Anne's Rewards Member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "Already a Rewards Member?",
    heroLinkAccountButton: "Link Your Account",
    stepTitle:
      "​",
    stepDescription: `<h1>AARP MEMBERS SAVE 10% AT AUNTIE ANNE'S EVERY DAY! </h1> </br> <p class="landing-process">Sign up for an Auntie Anne’s Rewards account or sign into your Auntie Anne’s Rewards account below and link your account with your AARP membership. ​ </p> </br> <p class="landing-completed-message">Once you have completed this process, you will be ready to save with Auntie Anne’s. The discount will be placed in your Auntie Anne’s Rewards account daily and there is no need to link your account again.​ </p>`,
    step1: {
      showSignUpLink: true,
      imageURL: "/auntieannes/step1.png",
      imageAlt: "Join Auntie Anne's Rewards",
      title: "Join Auntie Anne's Rewards",
      line1: {
        postlinkText: "for an Auntie Anne's Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/auntieannes/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/auntieannes/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-store, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBAA&cmp=ASI_P_MU_JN_AUNTIEANNES",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Auntie Anne's Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Auntie Anne's Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Auntie Anne's Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Auntie Anne's Rewards Benefits for AARP Members",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBAA&cmp=ASI_P_MU_JN_AUNTIEANNES",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Auntie Anne's Rewards for AARP Members",
    rewardsDisclaimer:
      "*10% off discount is valid at participating Auntie Anne's locations online, in app and in store. Offer is not valid on any catering orders or third-party delivery orders. This offer cannot be combined with any other offer. Member will receive one (1) 10% off discount per day. Exclusive of alcohol, tax, and gratuity. Members pays sales tax, subject to applicable law. Online and app orders subject to service fee and sales tax, where applicable. See checkout screen for amounts. In-store redemption not subject to service fee. Not for sale or resale. Void where prohibited. Cash value 1/100¢. No cash back. Additional exclusions may apply.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affiliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Auntie Anne's Rewards App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Auntie Anne's Rewards app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Auntie Anne's.",
      },
    ],
    spotlightImageURL:
      "https://focusbrands-dc.azureedge.net/dmm3bwsv3/assetstream.aspx?assetid=2361&mediaformatid=50100&destinationid=10016&download=false",
    spotlightImageAlt:
      "Download the Auntie Anne's Rewards app for iOS or Android",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  auntieannesschlotzskys: {
    pageTitle: "Join Auntie Anne’s Rewards!",
    heroBackgroundImage: "/auntieannes/aa-cs-hero.png",
    heroTitle: "Welcome Schlotzsky’s Rewards Members!",
    heroDescription:
      "Sign in or become a new Auntie Anne’s Rewards member to get 20% off your order",
    heroNote: "(up to $10)",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroSignUpSubtitle: "Not an Auntie Anne's Rewards Member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    stepTitle:
      "In just 3 easy steps, you'll be on your way to enjoying AARP member benefits and Auntie Anne's Rewards",
    step1: {
      showSignUpLink: true,
      imageURL: "/auntieannes/step1.png",
      imageAlt: "Join Auntie Anne's Rewards",
      title: "Join Auntie Anne's Rewards",
      line1: {
        postlinkText: "for an Auntie Anne's Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/auntieannes/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/auntieannes/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-store, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBAA&cmp=ASI_P_MU_JN_AUNTIEANNES",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Auntie Anne's Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Auntie Anne's Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Auntie Anne's Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Auntie Anne's Rewards Benefits for AARP Members",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBAA&cmp=ASI_P_MU_JN_AUNTIEANNES",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Auntie Anne's Rewards for AARP Members",
    rewardsDisclaimer: "",
    disclaimerText: "Join or sign into your Auntie Anne’s Rewards Account via this link and receive 20% off order (excluding taxes, fees, gift card purchases, or catering) through Rewards account (maximum $10 discount) at participating locations. Expires 14 days after linking accounts,  Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in  app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Auntie Anne's Rewards App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Auntie Anne's Rewards app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Auntie Anne's.",
      },
    ],
    spotlightImageURL:
      "https://focusbrands-dc.azureedge.net/dmm3bwsv3/assetstream.aspx?assetid=2361&mediaformatid=50100&destinationid=10016&download=false",
    spotlightImageAlt:
      "Download the Auntie Anne's Rewards app for iOS or Android",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  jamba: {
    heroBackgroundImage: "/jamba/hero.png",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "AARP Logo",
    heroDescription:
      "",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    stepTitle:
      "​",
    showBgOnMobile: true,
    hideSteps: true,
    stepSubtitle: "",
    stepDescription: `<h1>AARP members save 10% at Jamba every day! </h1> </br> <p class="landing-process">Sign up for a Jamba Rewards account or sign into your Jamba Rewards account below and link your account with your AARP membership. </p> </br> <p class="landing-completed-message">Once you have completed this process, you will be ready to save with Jamba. The discount will be placed in your Jamba Rewards account daily and there is no need to link your account again.​ </p>`,
    step1: {
      showSignUpLink: true,
      imageURL: "/jamba/step1.png",
      imageAlt: "Join Jamba Rewards",
      title: "Join Jamba Rewards",
      line1: {
        postlinkText: "for a Jamba Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/jamba/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/jamba/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-store, show the cashier your AARP member offer in the Jamba app and provide your phone number.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBJB&cmp=ASI_P_MU_JN_JAMBA",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Jamba Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Jamba Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Jamba Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Jamba Benefits for AARP Members",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBJB&cmp=ASI_P_MU_JN_JAMBA",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Jamba Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% off discount is valid at participating Jamba locations online, in app and in store. Excludes any Bruegger's Bagels stores, Las Vegas Strip, Safeway, Vons, Pavilions, convenience stores, airport or select university Jamba locations. Offer is not valid on any catering orders or third-party delivery orders. This offer cannot be combined with any other offer. Member will receive one (1) 10% off discount per day. Exclusive of alcohol, tax, and gratuity. Members pays sales tax, subject to applicable law. Online and app orders subject to service fee and sales tax, where applicable. See checkout screen for amounts. In-store redemption not subject to service fee. Not for sale or resale. Void where prohibited. Cash value 1/100¢. No cash back. Additional exclusions may apply.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affiliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  jambatmobile: {
    showSignInForm: true,
    pageTitle: "$4 OFF ANY PURCHASE​",
    infoText: "Sign up for a Jamba Rewards account or sign into your Jamba Rewards account below and the offer will automatically be applied to your account.",
    additionalInfoText: "Tax and fees excluded. See below for details.",
    heroBackgroundImage: "/jamba/hero.jpg",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "AARP Logo",
    heroDescription:
      "",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    stepTitle:
      "​",
    showBgOnMobile: true,
    hideSteps: true,
    stepSubtitle: "",
    stepDescription: `<h1>AARP members save 10% at Jamba every day! </h1> </br> <p class="landing-process">Sign up for a Jamba Rewards account or sign into your Jamba Rewards account below and link your account with your AARP membership. </p> </br> <p class="landing-completed-message">Once you have completed this process, you will be ready to save with Jamba. The discount will be placed in your Jamba Rewards account daily and there is no need to link your account again.​ </p>`,
    step1: {
      showSignUpLink: true,
      imageURL: "/jamba/step1.png",
      imageAlt: "Join Jamba Rewards",
      title: "Join Jamba Rewards",
      line1: {
        postlinkText: "for a Jamba Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/jamba/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/jamba/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-store, show the cashier your AARP member offer in the Jamba app and provide your phone number.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBJB&cmp=ASI_P_MU_JN_JAMBA",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Jamba Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Jamba Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Jamba Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Jamba Benefits for AARP Members",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBJB&cmp=ASI_P_MU_JN_JAMBA",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Jamba Benefits for AARP Members",
    rewardsDisclaimer:
      "",
    disclaimerText:
      `The $4 off Reward is valid through October 13, 2025, at 12:00 a.m. ET or close of business. Use of free Jamba Rewards Account required to redeem $4 off Reward. Taxes and fees excluded. Reward can be redeemed in the Jamba App, online at Jamba.com or in store at participating locations; not available in Puerto Rico or U.S. Virgin Islands, Bruegger's Bagels stores, Las Vegas Strip, Safeway, Vons, Pavilions, convenience stores, airports or select university Jamba locations. Limit 1 reward/customer, single use $4 off reward. Must apply Reward at checkout. Not valid with any other offer or reward or third-party delivery. Service fee applies to online, in app and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited. Reward not redeemable for cash, void if purchased, sold or bartered for cash, or if photocopied or reproduced. TM/© 2025 Jamba Juice Franchisor SPV LLC. All rights reserved.`,
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  fuelja: {
    customHTML: `<h1>FUEL UP ON JAMBA, SAVE UP TO 20¢/GAL AT THE PUMP!</h1>`,
    customHTMLClass: "text-center",
    heroBackgroundImage: "/jamba/fuel-rewards-hero.png",
    showBgOnMobile: true,
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription:
      "Enjoy 10% off your check* by linking your AARP membership to your Jamba Rewards account.",
    heroSignUpSubtitle: "Not a Jamba Rewards Member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "Already a Jamba Rewards Member?",
    heroLinkAccountButton: "Sign in",
    stepTitle:
      "In just 3 easy steps, you’ll be on your way to saving at the pump with Jamba and the Fuel Rewards® app.",
    stepSubtitle:
      "Earn a one-time 15¢/gal* discount at participating Shell stations when you link your Jamba Rewards account and your Fuel Rewards® account. Then, save 5¢/gal† for every $25 spent at Jamba from now through December 31, 2025",
    stepCustomHTML: `Sign up for a Jamba Rewards account below or sign into your Jamba Rewards account <a href="/fuelrewards/link-account">here</a>.`,
    step1: {
      showSignUpLink: true,
      imageURL: "/jamba/step1.png",
      imageAlt: "Join Jamba Rewards",
      title: "Jamba Rewards",
      line1: {
        postlinkText: "",
      },
      line2: {
        prelinkText: " ",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/jamba/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Link your Fuel Rewards® account.",
      },
      line2: {
        prelinkText: "Not a Fuel Rewards® member yet? Join the Fuel Rewards Program in Step 2",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/jamba/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Earn and Redeem Rewards",
      line1: {
        prelinkText:
          "Spend $25 at Jamba and save 5¢/gal† at participating Shell stations",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "internal",
    },
    joinAARP: {
      text: "",
      URL: "/",
      target: "_blank",
    },
    downloadSection: {
      text: "",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Jamba Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Jamba Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Jamba Benefits for AARP Members",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBJB&cmp=ASI_P_MU_JN_JAMBA",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Jamba Benefits for AARP Members",
    rewardsDisclaimer:
      `*Restrictions apply. Offer valid from November 13, 2023 – February 28, 2025 (the "Offer Period"). Earn a one-time 15¢/gal Fuel Rewards® savings up to 20 gallons (the "Linking Reward") when you successfully link your Fuel Rewards® account to your Jamba Rewards account. To qualify for this offer, during the Offer Period you must: (i) be a member of or join the JambaRewards program; (ii) be a member of or join the Fuel Rewards®program; (iii) link your Jamba Rewards account to your Fuel Rewards® account using the link above. After successful linking, swipe your Fuel Rewards® card, use a linked payment card, or enter your Alt ID when you fuel at a participating Shell station to redeem the Linking Reward. The Fuel Rewards® Linking Reward is single use and expires on the last day of the month following the month in which the Linking Reward was earned. Please see <a href="https://www.fuelrewards.com/" target="_blank">fuelrewards.com/status</a> for complete Fuel Rewards® Tier Status details and Terms and Conditions and <a href="https://www.jamba.com/legal#rewards-terms" target="_blank">https://www.jamba.com/legal#rewards-terms</a> for Jamba Rewards Terms and Conditions. See below for more terms and conditions.
    <br /><br />
    *Restrictions apply. Offer valid from November 13, 2023 – February 28, 2025 (the "Offer Period"). Earn 5¢/gal in Fuel Rewards® savings up to 20 gallons (the “Spend and Get Reward”) when you spend $25.00 in qualifying purchases at participating Jamba locations. To qualify for this offer, (i) the user must have successfully linked their Jamba Rewards account to their Fuel Rewards® account; and (ii) spend $25.00 in qualifying purchases (excluding purchasing gift cards, taxes and fees) at Jamba using their Jamba Rewards account qualifying purchases may be completed via multiple transactions. Each time the Jamba User spends $25 in qualifying purchases, the user is issued a Spend and Get Reward. To redeem the Spend and Get Reward,use a linked payment card or enter your Alt ID when you fuel at a participating Shell station to redeem the reward. Spend and Get Reward will be added to your Fuel Rewards® account within two (2) to three (3) business days of completing each $25.00 qualifying purchase(s) at Jamba using your Jamba Rewards account. All Spend and Get Rewards will expire on the last day of the month, one (1) month after the month in which rewards are posted to your account. Please see <a href="https://www.fuelrewards.com/terms" target="_blank">fuelrewards.com/terms</a> for complete Fuel Rewards®  program details and Terms and Conditions and <a href="https://www.jamba.com/legal#rewards-terms" target="_blank">https://www.jamba.com/legal#rewards-terms</a> for Jamba Rewards Terms and Conditions. See below for more terms and conditions.
    <br /><br />
    For all offers listed above, these terms apply. Void where prohibited, only available in US. Fuel Rewards® savings are limited to 20 gallons of fuel per purchase per vehicle or fraud limits placed by Shell and/or limits placed on your payment card by your financial institution, each of which may be lower. Dispenser may require a price of up to $0.109/gallon. Once you begin to dispense fuel using your Fuel Rewards®savings, you must dispense to the 20-gallon limit or you forfeit any remaining discount per gallons. If you have a rewards balance greater than the current per-gallon price of fuel, the price will roll down to the maximum capability of the fuel equipment, and your remaining rewards balance will be saved for a future fuel purchase. For purchases of $75 or more, please go inside to pay. Unbranded diesel and alternative fuels may not be eligible. The Fuel Rewards® program is owned and operated by Excentus Corporation. Offers may be modified or discontinued at any time without notice. Not valid where prohibited by law. Please see <a href="https://www.fuelrewards.com/terms" target="_blank">fuelrewards.com/terms</a> for complete FuelRewards® program details and Terms and Conditions. Other restrictions may apply. All trademarks are property of their respective owners.`,
    disclaimerText:
      "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",

  },

  fueljalto: {
    customHTML: `<h1>FUEL UP ON JAMBA, SAVE UP TO 25¢/GAL AT THE PUMP!</h1>`,
    customHTMLClass: "text-center",
    heroBackgroundImage: "/jamba/fuel-hero-lto.png",
    showBgOnMobile: true,
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription:
      "Enjoy 10% off your check* by linking your AARP membership to your Jamba Rewards account.",
    heroSignUpSubtitle: "Not a Jamba Rewards Member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "Already a Jamba Rewards Member?",
    heroLinkAccountButton: "Sign in",
    stepTitle:
      "In just 3 easy steps, you’ll be on your way to saving at the pump with Jamba and the Fuel Rewards® app.",
    stepSubtitle:
      "Earn a one-time 15¢/gal* discount at participating Shell stations when you link your Jamba Rewards account and your Fuel Rewards® account. Then, save 10¢/gal† for every $15 spent at Jamba from now through June 30, 2025",
    stepCustomHTML: `Sign up for a Jamba Rewards account below or sign into your Jamba Rewards account <a href="/fuelrewardslto/link-account">here</a>.`,
    step1: {
      showSignUpLink: true,
      imageURL: "/jamba/step1.png",
      imageAlt: "Join Jamba Rewards",
      title: "Jamba Rewards",
      line1: {
        postlinkText: "",
      },
      line2: {
        prelinkText: " ",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/jamba/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Link your Fuel Rewards® account.",
      },
      line2: {
        prelinkText: "Not a Fuel Rewards® member yet? Join the Fuel Rewards Program in Step 2",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/jamba/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Earn and Redeem Rewards",
      line1: {
        prelinkText:
          "Spend $15 at Jamba and save 10¢/gal† at participating Shell stations",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },

    signUp: {
      text: "",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "internal",
    },
    joinAARP: {
      text: "",
      URL: "/",
      target: "_blank",
    },
    downloadSection: {
      text: "",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Jamba Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Jamba Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Jamba Benefits for AARP Members",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBJB&cmp=ASI_P_MU_JN_JAMBA",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Jamba Benefits for AARP Members",
    rewardsDisclaimer:
      `*Restrictions apply. Offer valid from May 24, 2025 – June 30, 2025 (the "Offer Period"). Earn a one-time 15¢/gal Fuel Rewards® savings up to 20 gallons (the "Linking Reward") when you successfully link your Fuel Rewards® account to your Jamba Rewards account. To qualify for this offer, during the Offer Period you must: (i) be a member of or join the JambaRewards program; (ii) be a member of or join the Fuel Rewards®program; (iii) link your Jamba Rewards account to your Fuel Rewards® account using the link above. After successful linking, swipe your Fuel Rewards® card, use a linked payment card, or enter your Alt ID when you fuel at a participating Shell station to redeem the Linking Reward. The Fuel Rewards® Linking Reward is single use and expires on the last day of the month following the month in which the Linking Reward was earned. Please see <a href="https://www.fuelrewards.com/status" target="_blank">fuelrewards.com/status</a> for complete Fuel Rewards® Tier Status details and Terms and Conditions and <a href="https://www.jamba.com/legal#rewards-terms" target="_blank">https://www.jamba.com/legal#rewards-terms</a> for Jamba Rewards Terms and Conditions. See below for more terms and conditions.
    <br /><br />
    *Restrictions apply. Offer valid from May 24, 2025 – June 30, 2025 (the "Offer Period"). Earn 10¢/gal in Fuel Rewards® savings up to 20 gallons (the “Spend and Get Reward”) when you spend $15.00 in qualifying purchases at participating Jamba locations. To qualify for this offer, (i) the user must have successfully linked their Jamba Rewards account to their Fuel Rewards® account; and (ii) spend $15.00 in qualifying purchases (excluding purchasing gift cards, taxes and fees) at Jamba using their Jamba Rewards account qualifying purchases may be completed via multiple transactions. Each time the Jamba User spends $15 in qualifying purchases, the user is issued a Spend and Get Reward. To redeem the Spend and Get Reward,use a linked payment card or enter your Alt ID when you fuel at a participating Shell station to redeem the reward. Spend and Get Reward will be added to your Fuel Rewards® account within two (2) to three (3) business days of completing each $15.00 qualifying purchase(s) at Jamba using your Jamba Rewards account. All Spend and Get Rewards will expire on the last day of the month, one (1) month after the month in which rewards are posted to your account. Please see <a href="https://www.fuelrewards.com/terms" target="_blank">fuelrewards.com/terms</a> for complete Fuel Rewards®  program details and Terms and Conditions and <a href="https://www.jamba.com/legal#rewards-terms" target="_blank">https://www.jamba.com/legal#rewards-terms</a> for Jamba Rewards Terms and Conditions. See below for more terms and conditions.
    <br /><br />
    For all offers listed above, these terms apply. Void where prohibited, only available in US. Fuel Rewards® savings are limited to 20 gallons of fuel per purchase per vehicle or fraud limits placed by Shell and/or limits placed on your payment card by your financial institution, each of which may be lower. Dispenser may require a price of up to $0.109/gallon. Once you begin to dispense fuel using your Fuel Rewards®savings, you must dispense to the 20-gallon limit or you forfeit any remaining discount per gallons. If you have a rewards balance greater than the current per-gallon price of fuel, the price will roll down to the maximum capability of the fuel equipment, and your remaining rewards balance will be saved for a future fuel purchase. For purchases of $75 or more, please go inside to pay. Unbranded diesel and alternative fuels may not be eligible. The Fuel Rewards® program is owned and operated by Excentus Corporation. Offers may be modified or discontinued at any time without notice. Not valid where prohibited by law. Please see <a href="https://www.fuelrewards.com/terms" target="_blank">fuelrewards.com/terms</a> for complete FuelRewards® program details and Terms and Conditions. Other restrictions may apply. All trademarks are property of their respective owners.`,
    disclaimerText:
      "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  cinnabon: {
    heroBackgroundImage: "/cinnabon/hero.jpg",
    heroTitle: "",
    heroBrandingLogo: "",
    showBgOnMobile: true,
    heroBrandingLogoAlt: "AARP Logo",
    hideSteps: true,
    heroDescription:
      "Enjoy 10% off your check* by linking your AARP membership to your Cinnabon Rewards account.",
    heroSignUpSubtitle: "Not a Cinnabon Rewards Member?",
    heroSignUpButton: "Sign up",
    heroLinkAccountSubtitle: "Already a Cinnabon Rewards Member?",
    heroLinkAccountButton: "Link Your Account",
    stepTitle: "",
    stepDescription: `<h1>AARP members save 10% at Cinnabon every day! ​ </h1> </br> <p class="landing-process">Sign up for an Cinnabon Rewards account or sign into your Cinnabon Rewards account below and link your account with your AARP membership. ​ ​ </p> </br> <p class="landing-completed-message">Once you have completed this process, you will be ready to save with Cinnabon. The discount will be placed in your Cinnabon Rewards account daily and there is no need to link your account again.​​ </p>`,
    step1: {
      showSignUpLink: true,
      imageURL: "/cinnabon/step1.png",
      imageAlt: "Join Cinnabon Rewards",
      title: "Join Cinnabon Rewards",
      line1: {
        postlinkText: "for a Cinnabon Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/cinnabon/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/cinnabon/step3.png",
      imageAlt: "Redeem rewards!",
      title: "Redeem rewards!",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-bakery, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBCB&cmp=ASI_P_MU_JN_CINNABON",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Cinnabon app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Cinnabon rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Cinnabon rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Cinnabon Benefits for AARP Members",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignId=FEWUFBCB&cmp=ASI_P_MU_JN_CINNABON",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Cinnabon Rewards Benefits for AARP Members",
    rewardsDisclaimer:
      "*10% off discount is valid at participating Cinnabon locations online, in app and in store. Offer is not valid on any catering orders or third-party delivery orders. This offer cannot be combined with any other offer. Member will receive one (1) 10% off discount per day. Exclusive of alcohol, tax, and gratuity. Members pays sales tax, subject to applicable law. Online and app orders subject to service fee and sales tax, where applicable. See checkout screen for amounts. In-store redemption not subject to service fee. Not for sale or resale. Void where prohibited. Cash value 1/100¢. No cash back. Additional exclusions may apply.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affiliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Cinnabon Rewards App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Cinnabon Rewards app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Cinnabon",
      },
    ],
    spotlightImageURL: "/cinnabon/spotlight.jpg",
    spotlightImageAlt: "Download the Cinnavon Rewards app for iOS or Android",
    showNoticesSection: true,
    notices1ImageURL: "/cinnabon/cinschlo.png",
    notices1ImageAlt: "Cinnabon and Schlotzskys",
    notices1Id: "",
    notices1Title:
      "Trying to use the discount at a Cinnabon that is within a Schlotzsky’s?",
    notices1Description:
      `You will need to link your AARP membership with your Schlotzsky's Rewards account to receive your discount. Visit <a href="schlotzskys.com/aarp" target="_blank">Schlotzskys.com/AARP</a> to link your account.`,
    notices2Id: "",
    notices2ImageURL: "/cinnabon/cinaa.jpg",
    notices2ImageAlt: "",
    notices2Title:
      "Trying to use the discount at a Cinnabon that is connected to an Auntie Anne’s?",
    notices2Description:
      `You will need to link your AARP membership with your Auntie Anne’s Rewards account to receive your discount. Visit <a href="auntieannes.com/aarp" target="_blank">AuntieAnnes.com/AARP</a> to link your account.`,
  },
  carvel: {
    showBgOnMobile: true,
    heroBackgroundImage: "/carvel/hero.png",
    heroBackgroundImage2: "/carvel/bg2-fallback.png",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: " ",
    stepTitle:
      "Carvel has been a place for Fudgie fanatics to eat, laugh, & enjoy the delicious company of wacky characters like &reg;Fudgie The Whale, Cookie Puss, & Hug Me Bear since 1934. So not surprisingly, it's time to introduce another character to our deliciously zany crew.",
    step1: {
      showSignUpLink: false,
      imageURL: "/carvel/character1.png",
      imageAlt: "",
      title: "Crunchie Munchie Monster",
      line1: {
        postlinkText: "",
        prelinkText:
          "This monster is a misunderstood mutant that became discombobulated when his Flying Saucer got sucked into a black donut hole eons ago, magically transporting him to a Carvel on 48th in Manhattan looking like a misshapen heap of chocolate with a toothy  grin. What most people don’t know is that his many pieces crumbled apart and came back together sweeter and softer than ever. So, please don’t judge this monster by his crunchie exterior.",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "/carvel/character3.png",
      imageAlt: "",
      title: "Coney Carl",
      line1: {
        prelinkText:
          "We’ve all heard of the great NYC landmarks, but there’s another that deserves initial caps, too – our friend, Coney Carl. Carl's a national treasure making history every day with his valiant vanilla smile and heart so soft it flows sweetness at every turn. Tasty tidbit: When Tom Carvel’s ice cream truck broke down in 1934, Carl suggested Tom turn the melting ice cream into America’s Original Soft Serve. And the rest is, shall we say, ice cream history.",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "/carvel/character2.png",
      imageAlt: "",
      title: "Sunny Sprinkles",
      line1: {
        prelinkText:
          "Sunny is a flower on a mission to spread positivity, compliments, and sprinkles across the world – or at least from Central Park to the Brooklyn Bridge. You'll find her popping up at parties with her sprinkle-coated petals offering colorful praise and admiration, and just before she leaves she’ll shine bright and bloom pure joy for all to see. It’s amazing what a little sprinkle of confidence and yumminess can do.",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "",
      URL: "",
      target: "",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "",
    },
    downloadSection: {
      text: "",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "",
    signUpSubtitle: "",
    signUpButton: "",
    linkAccountSubtitle: "",
    linkAccountButton: "",
    joinSubtitle: "",
    joinButton: {
      text: "",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    rewardsDisclaimer: "",
    disclaimerText: "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "",
    spotlightTitle: "",
    spotlightDescription: [
      {
        text: "",
      },
      {
        text: "",
      },
      {
        text: "",
      },
    ],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2ImageAlt: "",
    notices2Title: "",
    notices2Description: "",
  },
  fetchmca: {
    pageTitle: "FETCH MEMBERS EARN 3,000 FETCH POINTS WHEN YOU JOIN MCALISTER'S REWARDS!",
    heroBackgroundImage: "/fetchmca/hero.jpg",
    showBgOnMobile: true,
    heroTitle:
      "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "",
    heroSignUpSubtitle: "",
    heroSignUpUrl: "/fetch/sign-up",
    heroSignUpButton: "Join now",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    infoText:
      "You must enroll in McAlister’s Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment.",
    introText:
      "In addition to earning 2,000 Fetch Points for enrolling in McAlister’s Rewards, you will earn McAlister’s Rewards points on every purchase towards some crave-worthy rewards you can redeem when and how you want. Order your favorites faster than ever, get exclusive offers from your local McAlister’s Deli and more.",
    stepTitle: "$1 spent ~ 10 points",
    stepSubtitle:
      "McAlister's Rewards members earn points on every purchase towards your choice of rewards, plus special offers and a birthday treat!",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "500 Points",
      line1: {
        postlinkText: "Your choice of beverage or kids meal",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1000 Points",
      line1: {
        prelinkText: "Your choice of a Side, Cup of Soup, or Dessert",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1500 Points",
      line1: {
        prelinkText:
          "Your choice of Entrée (Sandwich, Salad, Spud, or Choose 2)",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "download the McAlister's Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a McAlister Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a McAlister Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Fetch",
    joinButton: {
      text: "Join Fetch",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Fetch",
    rewardsDisclaimer: `Offer available only to new McAlister’s Rewards Members who sign up using partners.mcalistersdeli.com/fetch on or before 12/31/2025 who have an active Fetch account. Must enroll in McAlister’s Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment. Fetch points are controlled and distributed by Fetch, Inc. which is not associated with McAlister’s Deli or Focus Brands LLC and are subject to Fetch Terms and Conditions available at <a href="https://fetch.com/terms-of-service" target="_blank">https://fetch.com/terms-of-service</a> `,
    disclaimerText: "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
  },
  fetchaa: {
    pageTitle: "FETCH MEMBERS EARN 3,000 FETCH POINTS WHEN YOU JOIN AUNTIE ANNE'S REWARDS!",
    heroBackgroundImage: "/fetchaa/fetch-hero.jpg",
    showBgOnMobile: true,
    heroTitle:
      "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "",
    heroSignUpSubtitle: "",
    heroSignUpUrl: "/fetch/sign-up",
    heroSignUpButton: "Join now",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    infoText:
      "You must enroll in Auntie Anne's Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment.",
    introText:
      "",
    stepTitle: "",
    stepSubtitle:
      "",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "500 Points",
      line1: {
        postlinkText: "Your choice of beverage or kids meal",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1000 Points",
      line1: {
        prelinkText: "Your choice of a Side, Cup of Soup, or Dessert",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1500 Points",
      line1: {
        prelinkText:
          "Your choice of Entrée (Sandwich, Salad, Spud, or Choose 2)",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "download the McAlister's Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a McAlister Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a McAlister Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Fetch",
    joinButton: {
      text: "Join Fetch",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Fetch",
    rewardsDisclaimer: `Offer available only to new Auntie Anne's Rewards Members who sign up using partners.auntieannes.com/fetch on or before 12/31/2025 who have an active Fetch account. Must enroll in Auntie Anne's Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment. Fetch points are controlled and distributed by Fetch, Inc. which is not associated with Auntie Anne's or GoTo Foods LLC and are subject to Fetch Terms and Conditions available at <a href="https://fetch.com/terms-of-service" target="_blank">https://fetch.com/terms-of-service</a> `,
    disclaimerText: "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
  },
  fetchsl: {
    pageTitle: "FETCH MEMBERS EARN 3,000 FETCH POINTS WHEN YOU SIGN UP FOR SCHLOTZSKY'S REWARDS!",
    heroBackgroundImage: "/fetchsl/hero.jpg",
    showBgOnMobile: true,
    heroTitle:
      "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "",
    heroSignUpSubtitle: "",
    heroSignUpUrl: "/fetch/sign-up",
    heroSignUpButton: "Join now",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    infoText:
      "You must enroll in Schlotzsky’s Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment.",
    introText:
      "In addition to earning 2,000 Fetch Points for enrolling in McAlister’s Rewards, you will earn McAlister’s Rewards points on every purchase towards some crave-worthy rewards you can redeem when and how you want. Order your favorites faster than ever, get exclusive offers from your local McAlister’s Deli and more.",
    stepTitle: "$1 spent ~ 10 points",
    stepSubtitle:
      "McAlister's Rewards members earn points on every purchase towards your choice of rewards, plus special offers and a birthday treat!",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "500 Points",
      line1: {
        postlinkText: "Your choice of beverage or kids meal",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1000 Points",
      line1: {
        prelinkText: "Your choice of a Side, Cup of Soup, or Dessert",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1500 Points",
      line1: {
        prelinkText:
          "Your choice of Entrée (Sandwich, Salad, Spud, or Choose 2)",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "download the McAlister's Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a McAlister Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a McAlister Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Fetch",
    joinButton: {
      text: "Join Fetch",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Fetch",
    rewardsDisclaimer: `Offer available only to new Schlotzsky’s Rewards Members who sign up using partners.schlotzskys.com/fetch on or before 12/31/2025 who have an active Fetch account. Must enroll in Schlotzsky’s Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment. Fetch points are controlled and distributed by Fetch, Inc. which is not associated with Schlotzsky’s or Focus Brands LLC and are subject to Fetch Terms and Conditions available at <a href="https://fetch.com/terms-of-service" target="_blank">https://fetch.com/terms-of-service</a>`,
    disclaimerText: "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
  },
  fetchci: {
    pageTitle: "FETCH MEMBERS EARN 3,000 FETCH POINTS WHEN YOU JOIN CINNABON REWARDS!",
    heroBackgroundImage: "/fetchci/hero.png",
    showBgOnMobile: true,
    heroTitle:
      "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "",
    heroSignUpSubtitle: "",
    heroSignUpUrl: "/fetch/sign-up",
    heroSignUpButton: "Join now",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    infoText:
      "You must enroll in Cinnabon Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment.",
    introText: "",
    stepTitle: "$1 spent ~ 10 points",
    stepSubtitle:
      "McAlister's Rewards members earn points on every purchase towards your choice of rewards, plus special offers and a birthday treat!",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "500 Points",
      line1: {
        postlinkText: "Your choice of beverage or kids meal",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1000 Points",
      line1: {
        prelinkText: "Your choice of a Side, Cup of Soup, or Dessert",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1500 Points",
      line1: {
        prelinkText:
          "Your choice of Entrée (Sandwich, Salad, Spud, or Choose 2)",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "download the McAlister's Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a McAlister Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a McAlister Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Fetch",
    joinButton: {
      text: "Join Fetch",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Fetch",
    rewardsDisclaimer: `Offer available only to new Cinnabon Rewards Members who sign up using partners.cinnabon.com/fetch on or before 12/31/2025 who have an active Fetch account. Must enroll in Cinnabon Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment. Fetch points are controlled and distributed by Fetch, Inc. which is not associated with Cinnabon or Focus Brands LLC and are subject to Fetch Terms and Conditions available at <a href="https://fetch.com/terms-of-service" target="_blank">https://fetch.com/terms-of-service</a>`,
    disclaimerText: "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
  },
  mcalisterscinnabon: {
    pageTitle: "",
    heroBackgroundImage: "/mcalisters/mca-cs-hero.png",
    heroTitle:
      "Welcome Cinnabon Rewards Members! Sign in or become a new McAlister’s Rewards Member and get 20% off your order",
    heroDescription: "(up to $10)",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroSignUpSubtitle: "",
    heroSignUpUrl: "/fetch/sign-up",
    heroSignUpButton: "Join now",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    infoText: "",
    introText: "",
    stepTitle: "$1 spent ~ 10 points",
    stepSubtitle:
      "McAlister's Rewards members earn points on every purchase towards your choice of rewards, plus special offers and a birthday treat!",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "500 Points",
      line1: {
        postlinkText: "Your choice of beverage or kids meal",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1000 Points",
      line1: {
        prelinkText: "Your choice of a Side, Cup of Soup, or Dessert",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1500 Points",
      line1: {
        prelinkText:
          "Your choice of Entrée (Sandwich, Salad, Spud, or Choose 2)",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "download the McAlister's Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a McAlister Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a McAlister Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Fetch",
    joinButton: {
      text: "Join Fetch",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Fetch",
    rewardsDisclaimer: [
      {
        text: "",
      },
      {
        link: "",
        text: "",
      },
      { text: "" },
    ],
    disclaimerText: "Join or sign into your McAlister's Rewards Account via this link and receive 20% off order (excluding taxes, fees, gift card purchases, or catering) through Rewards account (maximum $10 discount) at participating locations. Expires 14 days after linking accounts,  Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in  app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
  },
  cinnabonmcalisters: {
    pageTitle: "",
    heroBackgroundImage: "/cinnabon/cin-cs-hero.png",
    heroTitle:
      "Welcome McAlister’s Rewards Members! Sign in or become a new Cinnabon member and get 20% off your order",
    heroDescription: "(up to $10)",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroSignUpSubtitle: "",
    heroSignUpUrl: "/fetch/sign-up",
    heroSignUpButton: "Join now",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    infoText: "",
    introText: "",
    stepTitle: "$1 spent ~ 10 points",
    stepSubtitle:
      "McAlister's Rewards members earn points on every purchase towards your choice of rewards, plus special offers and a birthday treat!",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "500 Points",
      line1: {
        postlinkText: "Your choice of beverage or kids meal",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1000 Points",
      line1: {
        prelinkText: "Your choice of a Side, Cup of Soup, or Dessert",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1500 Points",
      line1: {
        prelinkText:
          "Your choice of Entrée (Sandwich, Salad, Spud, or Choose 2)",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "download the McAlister's Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a McAlister Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a McAlister Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Fetch",
    joinButton: {
      text: "Join Fetch",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Fetch",
    rewardsDisclaimer: [{ text: "" }, { link: "" }, { text: "." }],
    disclaimerText: "Join or sign into your Cinnabon Rewards Account via this link and receive 20% off order (excluding taxes, fees, gift card purchases, or catering) through Rewards account (maximum $10 discount) at participating locations. Expires 14 days after linking accounts,  Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in  app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
  },
  cinnabonmoes: {
    pageTitle: "",
    heroBackgroundImage: "/fetchci/hero.png",
    heroTitle:
      "Welcome Moe's Rewards Members! Sign in or become a new Cinnabon member and get 20% off your order",
    heroDescription: "(up to $10)",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroSignUpSubtitle: "",
    heroSignUpUrl: "/fetch/sign-up",
    heroSignUpButton: "Join now",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    infoText: "",
    introText: "",
    stepTitle: "$1 spent ~ 10 points",
    stepSubtitle:
      "McAlister's Rewards members earn points on every purchase towards your choice of rewards, plus special offers and a birthday treat!",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "500 Points",
      line1: {
        postlinkText: "Your choice of beverage or kids meal",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1000 Points",
      line1: {
        prelinkText: "Your choice of a Side, Cup of Soup, or Dessert",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1500 Points",
      line1: {
        prelinkText:
          "Your choice of Entrée (Sandwich, Salad, Spud, or Choose 2)",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "download the McAlister's Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a McAlister Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a McAlister Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Fetch",
    joinButton: {
      text: "Join Fetch",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Fetch",
    rewardsDisclaimer: [{ text: "" }, { link: "" }, { text: "." }],
    disclaimerText: "Join or sign into your Cinnabon Rewards Account via this link and receive 20% off order (excluding taxes, fees, gift card purchases, or catering) through Rewards account (maximum $10 discount) at participating locations. Expires 14 days after linking accounts,  Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in  app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
  },
  moescinnabon: {
    pageTitle: "",
    heroBackgroundImage: "/moes/moe-cs-hero.png",
    heroTitle:
      "Welcome Cinnabon Rewards Members! Sign in or become a new Moe’s member and get 20% off your order",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "(up to $10)",
    heroSignUpSubtitle: "",
    heroSignUpUrl: "/fetch/sign-up",
    heroSignUpButton: "Join now",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    infoText: "",
    introText: "",
    stepTitle: "$1 spent ~ 10 points",
    stepSubtitle:
      "McAlister's Rewards members earn points on every purchase towards your choice of rewards, plus special offers and a birthday treat!",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "500 Points",
      line1: {
        postlinkText: "Your choice of beverage or kids meal",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1000 Points",
      line1: {
        prelinkText: "Your choice of a Side, Cup of Soup, or Dessert",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1500 Points",
      line1: {
        prelinkText:
          "Your choice of Entrée (Sandwich, Salad, Spud, or Choose 2)",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "download the McAlister's Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a McAlister Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a McAlister Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Fetch",
    joinButton: {
      text: "Join Fetch",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Fetch",
    rewardsDisclaimer: [
      {
        text: "",
      },
      {
        link: "",
        text: "",
      },
      { text: "" },
    ],
    disclaimerText: "Join or sign into your Moe's Rewards Account via this link and receive 20% off order (excluding taxes, fees, gift card purchases, or catering) through Rewards account (maximum $10 discount) at participating locations. Expires 14 days after linking accounts,  Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in  app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
  },
  sweepsjamba: {
    introText: ``,
    pageTitle:
      "Sign up or sign in below for a chance to win a trip to see Disney Princess – The Concert and experience your favorite Disney Princess songs performed live by Broadway stars",
    heroBackgroundImage: "/jamba/sweeps-hero.png",
    heroCustomHTML: ``,
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "",
    heroNote: "",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "Join Jamba Rewards!",
    heroLinkAccountButton: "",
    infoText: "To enter sign in or enroll for a Jamba Rewards account.",
    stepTitle: "",
    stepSubtitle: "",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        postlinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "download the McAlister's Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a McAlister Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a McAlister Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Fetch",
    joinButton: {
      text: "Join Fetch",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Fetch",
    rewardsDisclaimer: [
      {
        text: "Offer available only to new McAlister's Rewards Members who sign up using partners.mcalistersdeli.com/fetch on or before 12/31/2023 who have an active Fetch account. Must enroll in McAlister’s Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment. Fetch Points are controlled and distributed by Fetch, Inc. which is not associated with McAlisters Deli or Focus Brands LLC and are subject to Fetch Terms and Conditions available at ",
      },
      {
        link: "https://www.fetchrewards.com/terms-of-service",
        text: "https://www.fetchrewards.com/terms-of-service",
      },
      { text: "." },
    ],
    disclaimerText: "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
    alternateEntryFormText: `For the <a href="/Princess_TheConcert_JambaSweepstakes_EntryForm.pdf" target="_blank">Alternate Free Method of Entry Form click here</a>`,
    entryFormDisclaimer: `NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT IMPROVE YOUR CHANCES OF WINNING. Sweepstakes begins on or about 11/21/23 at 8:00a.m ET and ends on 12/21/23. Open only to legal U.S. residents who are 21 years of age or older. See Official Rules at <a href="/concert/rules">https://sweeps.jamba.com/concert/rules</a> including additional eligibility restrictions, prize descriptions/restrictions/ARVs and complete details. Odds of winning depend on the number of eligible entries received. Limit one (1) entry per person. Void where prohibited. Sponsor: Jamba Juice Franchisor SPV LLC.<br /><br />This production features Broadway performers appearing as themselves. Costumed Disney characters do not appear at this event. Cast subject to change.<br /><br /><img src="/jamba/DisneyConcerts_logo_black.png" alt="Disney concerts logo" style="width: 140px;" /><br />`,
  },
  sweepsmca: {
    pageTitle:
      "Sign up or sign in below and purchase a Dr pepper at mcalister’s deli to enter",
    heroBackgroundImage: "/sweepsmca/hero-dr-pepper-new.jpg",
    heroCustomHTML: `
      <div class="title-image-wrapper">
        <h1 class="title">Taste victory</h1>
      </div>
      <p class="description">
        Enter to win 2 tickets to the 2023 Dr Pepper Big 12 Football Championship, plus hotel accommodations and airfare
      </p>
      <ul class="logo-wrapper">
        <li><img width="88px" src="/sweepsmca/dr-pepper-logo.png"/></li>
        <li><img width="88px" src="/sweepsmca/drpepper-big12-logo.png"/></li>
      </ul>
      <small>Official sponsor of the BIG 12</small>
      `,
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription:
      "Sign in or sign up to enter for a chance to win this sweepstakes",
    heroCustomClass: "dr-pepper-campaign",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    infoText:
      "NO PURCHASE NECESSARY. You and a friend could go to the 2023 Dr Pepper Big 12 Football Championship Game in Arlington, TX! Enter for a chance to win. One (1) grand prize includes two (2) tickets to the 2023 Dr Pepper Big 12 Football Championship on Saturday, December 2, 2023, along with two (2) roundtrip airfare tickets and two (2) hotel nights in the DFW area. The sweepstakes period is between September 1, 2023, and September 30, 2023. Entry requires McAlister's Rewards credentials. Sign up or sign into McAlister's Rewards and follow the steps to confirm your sweepstakes entry. For Purchase Entrants, make a Dr Pepper purchase (kids or adult size, Dr Pepper or Diet Dr Pepper). Alternatively, entrants may mail in an entry form to “McAlister's Deli and 2023 Dr Pepper Big 12 Football Championship Sweepstakes”, C/O McAlister’s Deli Franchisor SPV LLC, ATTN McAlister’s Deli Marketing Department, 5620 Glenridge Drive, Atlanta, GA 30342 with the full entry form details below.",
    stepTitle: "$1 spent ~ 10 points",
    stepSubtitle:
      "McAlister's Rewards members earn points on every purchase towards your choice of rewards, plus special offers and a birthday treat!",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "500 Points",
      line1: {
        postlinkText: "Your choice of beverage or kids meal",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1000 Points",
      line1: {
        prelinkText: "Your choice of a Side, Cup of Soup, or Dessert",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "1500 Points",
      line1: {
        prelinkText:
          "Your choice of Entrée (Sandwich, Salad, Spud, or Choose 2)",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "download the McAlister's Rewards app",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a McAlister Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a McAlister Rewards member?",
    linkAccountButton: "Link Account",
    joinSubtitle: "Fetch",
    joinButton: {
      text: "Join Fetch",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Fetch",
    rewardsDisclaimer: [
      {
        text: "Offer available only to new McAlister's Rewards Members who sign up using partners.mcalistersdeli.com/fetch on or before 12/31/2023 who have an active Fetch account. Must enroll in McAlister’s Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment. Fetch Points are controlled and distributed by Fetch, Inc. which is not associated with McAlisters Deli or Focus Brands LLC and are subject to Fetch Terms and Conditions available at ",
      },
      {
        link: "https://www.fetchrewards.com/terms-of-service",
        text: "https://www.fetchrewards.com/terms-of-service",
      },
      { text: "." },
    ],
    disclaimerText: "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "download-app",
    spotlightTitle: "Jamba App",
    spotlightDescription: [
      {
        text: "To use your 10% off reward, download the Jamba app, sign in and apply the reward at checkout (at participating locations only).",
      },
      {
        text: 'In-store, tap "REWARDS" to find the AARP offer, then tap "REDEEM IN-STORE", then scan the reward barcode. Or, provide your mobile number at checkout.',
      },
      {
        text: "Plus, use the app to track your points, order your favorites faster than ever and get exclusive offers from Jamba.",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Download the Jamba Rewards app for iOS or Android",
    alternateEntryFormText: `For the <a href="/sweepsmca/Dr Pepper Big 12 Footbal Championship Sweepstakes Entry Form FINAL.pdf" target="_blank">Alternate Free Method of Entry Form click here</a>`,
    entryFormDisclaimer: `NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT IMPROVE YOUR CHANCES OF WINNING. Sweepstakes begins on or about 9/1/23 at 8:00 a.m. ET and ends on 9/30/23 at 11:59 p.m. ET. Open only to legal U.S. residents who are 21 years of age or older. See Official Rules at <a href="/DrPepperBig12/rules">https://sweeps.mcalistersdeli.com/DrPepperBig12/rules</a> including additional eligibility restrictions, prize descriptions/restrictions/ARVs and complete details. Odds of winning depend on the number of eligible entries received. Limit one (1) entry per person. Void where prohibited. Sponsor: McAlister’s Franchisor SPV LLC. Dr Pepper is a registered trademark of Dr Pepper/Seven Up, Inc. 2023.`,
  },
  channelsaa: {
    pageTitle: "Enroll in Auntie Anne’s Rewards!",
    heroBackgroundImage: "/auntieannes/hero.png",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    showBgOnMobile: true,
    heroDescription: "",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    infoText:
      "You’ll earn a free pretzel after your first order. With each order you can earn points towards pretzels. Oh how sweet (or salty) it is!",
    introText: "",
    stepTitle: "",
    stepSubtitle: "",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        postlinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "",
      URL: "",
      target: "",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "",
    },
    downloadSection: {
      text: "",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "",
    signUpSubtitle: "",
    signUpButton: "",
    linkAccountSubtitle: "",
    linkAccountButton: "",
    joinSubtitle: "",
    joinButton: {
      text: "",
      URL: "",
      target: "",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    rewardsDisclaimer: [{ text: "" }, { link: "", text: "" }, { text: "" }],
    disclaimerText: "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "",
    spotlightTitle: "",
    spotlightDescription: [
      {
        text: "",
      },
      {
        text: "",
      },
      {
        text: "",
      },
    ],
    spotlightImageURL: "",
    spotlightImageAlt: "",
  },
  channelsci: {
    pageTitle: "Enroll in Cinnabon Rewards!",
    heroBackgroundImage: "/channelsci/hero.png",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    showBgOnMobile: true,
    heroDescription: "",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    infoText:
      "You’ll earn points toward irresistible rewards with every purchase. So basically, the more you treat yourself to your Cinnabon favorites, the more rewards you’ll get.​",
    introText: "",
    stepTitle: "",
    stepSubtitle: "",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        postlinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "",
      URL: "",
      target: "",
    },
    signIn: {
      text: "",
      URL: "",
      target: "",
    },
    linkAccount: {
      text: "",
      URL: "",
      target: "",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "",
    },
    downloadSection: {
      text: "",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "",
    signUpSubtitle: "",
    signUpButton: "",
    linkAccountSubtitle: "",
    linkAccountButton: "",
    joinSubtitle: "",
    joinButton: {
      text: "",
      URL: "",
      target: "",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    rewardsDisclaimer: [{ text: "" }, { link: "", text: "" }, { text: "" }],
    disclaimerText: "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "",
    spotlightTitle: "",
    spotlightDescription: [
      {
        text: "",
      },
      {
        text: "",
      },
      {
        text: "",
      },
    ],
    spotlightImageURL: "",
    spotlightImageAlt: "",
  },
  fetchja: {
    pageTitle: "Join Jamba Rewards!",
    infoText:
      "You must enroll in Jamba Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment.",
    heroBackgroundImage: "/fetchja/hero.png",
    heroTitle: "Welcome Fetch Members!",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "Earn 3,000 Fetch Points when you join Jamba Rewards!",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    stepTitle: "",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        postlinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "",
    signUpSubtitle: "",
    signUpButton: "",
    linkAccountSubtitle: "",
    linkAccountButton: "",
    joinSubtitle: "",
    joinButton: {
      text: "",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    rewardsDisclaimer:
      "Offer available only to new Jamba Rewards Members who sign up using partners.jamba.com/fetch on or before 12/31/2023 who have an active Fetch account. Must enroll in Jamba Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment. Fetch Points are controlled and distributed by Fetch, Inc. which is not associated with Jamba or Focus Brands LLC and are subject to Fetch Terms and Conditions available at https://www.fetchrewards.com/terms-of-service.",
    disclaimerText: "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "",
    spotlightTitle: "",
    spotlightDescription: [
      {
        text: "",
      },
      {
        text: "",
      },
      {
        text: "",
      },
    ],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  jambaauntieannes: {
    pageTitle: "",
    infoText: "",
    heroBackgroundImage: "/fetchja/hero.png",
    heroTitle: "Welcome Auntie Anne’s rewards Members!",
    heroNote: "(up to $10)",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription:
      "Sign in or become a new Jamba Rewards member to get 20% off your order",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    stepTitle: "",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        postlinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "",
    signUpSubtitle: "",
    signUpButton: "",
    linkAccountSubtitle: "",
    linkAccountButton: "",
    joinSubtitle: "",
    joinButton: {
      text: "",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    rewardsDisclaimer: "",
    disclaimerText: "Join or sign into your Jamba Rewards Account via this link and receive 20% off order (excluding taxes, fees, gift card purchases, or catering) through Rewards account (maximum $10 discount) at participating locations. Expires 14 days after linking accounts,  Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in  app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "",
    spotlightTitle: "",
    spotlightDescription: [
      {
        text: "",
      },
      {
        text: "",
      },
      {
        text: "",
      },
    ],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  auntieannesjamba: {
    pageTitle: "Join Auntie Anne’s Rewards!",
    infoText: "",
    heroBackgroundImage: "/auntieannes/aa-cs-hero.png",
    heroTitle: "Welcome Jamba rewards Members!",
    heroNote: "(up to $10)",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription:
      "Sign in or become a new Auntie Anne’s Rewards member to get 20% off your order",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    stepTitle: "",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        postlinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "",
    signUpSubtitle: "",
    signUpButton: "",
    linkAccountSubtitle: "",
    linkAccountButton: "",
    joinSubtitle: "",
    joinButton: {
      text: "",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    rewardsDisclaimer: "",
    disclaimerText: "Join or sign into your Auntie Anne’s Rewards Account via this link and receive 20% off order (excluding taxes, fees, gift card purchases, or catering) through Rewards account (maximum $10 discount) at participating locations. Expires 14 days after linking accounts,  Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in  app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "",
    spotlightTitle: "",
    spotlightDescription: [
      {
        text: "",
      },
      {
        text: "",
      },
      {
        text: "",
      },
    ],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  schlotzskysauntieannes: {
    pageTitle: "",
    infoText: "",
    heroBackgroundImage: "/schlotzskys/sc-cs-hero.png",
    heroTitle:
      "Welcome Auntie Anne’s Rewards Members! Sign in or become a new Schlotzsky’s member and get 20% off your order",
    heroNote: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "(up to $10)",
    heroSignUpSubtitle: "",
    heroSignUpButton: "",
    heroLinkAccountSubtitle: "",
    heroLinkAccountButton: "",
    stepTitle: "",
    step1: {
      showSignUpLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        postlinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step2: {
      showLinkAccountLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
      },
      line2: {
        prelinkText: "",
      },
    },
    step3: {
      showAppLink: false,
      imageURL: "",
      imageAlt: "",
      title: "",
      line1: {
        prelinkText: "",
        postlinkText: "",
      },
      line2: {
        text: "",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "",
      URL: "",
      target: "_blank",
    },
    downloadSection: {
      text: "",
      URL: "",
      target: "",
    },
    showButtonListSection: false,
    linkTitle: "",
    signUpSubtitle: "",
    signUpButton: "",
    linkAccountSubtitle: "",
    linkAccountButton: "",
    joinSubtitle: "",
    joinButton: {
      text: "",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    rewardsDisclaimer: "",
    disclaimerText: "Join or sign into your Schlotzsky's Rewards Account via this link and receive 20% off order (excluding taxes, fees, gift card purchases, or catering) through Rewards account (maximum $10 discount) at participating locations. Expires 14 days after linking accounts,  Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in  app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    showSpotlightSection: false,
    spotlightID: "",
    spotlightTitle: "",
    spotlightDescription: [
      {
        text: "",
      },
      {
        text: "",
      },
      {
        text: "",
      },
    ],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
  sweepsmoes: {
    showBgOnMobile: true,
    heroBackgroundImage: "/sweepsmoes/hero.png",
    heroTitleHTML:
      "Buy a drink at MOE'S<span>*</span> </br><span>&</span> Sign Up<span>/</span>In for a chance to win",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    heroDescription: "",
    heroSignUpSubtitle: "Moe Rewards member?",
    heroSignUpButton: "Log in and Enter",
    heroSignUpUrl: "#login",
    heroLinkAccountSubtitle: "Not a Moe Rewards member?",
    heroLinkAccountButton: "Sign up here",
    stepTitle:
      "In just 3 easy steps, you'll be on your way to enjoying AARP member benefits and Moe Rewards!",
    step1: {
      showSignUpLink: true,
      imageURL: "/moes/step1.png",
      imageAlt: "Join Moe Rewards",
      title: "Join Moe Rewards",
      line1: {
        postlinkText: "for a Moe Rewards account.",
      },
      line2: {
        prelinkText: "Already have an account?",
      },
    },
    step2: {
      showLinkAccountLink: true,
      imageURL: "/moes/step2.png",
      imageAlt: "Link your Account",
      title: "Link your Account",
      line1: {
        prelinkText: "Provide us with your AARP member number to",
      },
      line2: {
        prelinkText: "Not an AARP member yet?",
      },
    },
    step3: {
      showAppLink: true,
      imageURL: "/moes/step3.png",
      imageAlt: "Redeem Rewards",
      title: "Redeem Rewards",
      line1: {
        prelinkText: "To use your 10% off reward,",
        postlinkText: ", sign in and apply the reward at checkout.",
      },
      line2: {
        text: "In-restaurant, scan the reward barcode or provide your mobile number at checkout.",
      },
    },
    signUp: {
      text: "Sign Up",
      URL: "/sign-up",
      target: "internal",
    },
    signIn: {
      text: "Sign In",
      URL: "/link-account",
      target: "internal",
    },
    linkAccount: {
      text: "link to your accounts.",
      URL: "/link-account",
      target: "internal",
    },
    joinAARP: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    downloadSection: {
      text: "download the Moe Rewards app",
      URL: "#download-app",
      target: "",
    },
    showButtonListSection: true,
    linkTitle: "What are you waiting for? Sign up today!",
    signUpSubtitle: "Not a Moe Rewards member?",
    signUpButton: "Sign up",
    linkAccountSubtitle: "Already a Moe Rewards member?",
    linkAccountButton: "Link Account",
    linkAccountButtonURL: "/soundsofsummer/rewards-signup",
    joinSubtitle: "Not an AARP member?",
    joinButton: {
      text: "Join AARP",
      URL: "https://appsec.aarp.org/mem/join?campaignid=feldmoe1",
      target: "_blank",
    },
    showBenefitsSection: true,
    benefitsTitle: "",
    rewardsDisclaimer:
      "*NO PURCHASE NECESSARY. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. SEE FULL OFFICIAL RULES FOR NO PURCHASE METHOD OF ENTRY: HTTPS://SWEEPS.MOES.COM/SOUNDSOFSUMMER/RULES. OPEN ONLY TO LEGAL RESIDENTS 50 U.S. & D.C., 18 YEARS+. VOID WHERE PROHIBITED. BEGINS 9:00 A.M. ET ON 6/14/2023 AND ENDS AT 11:00 P.M. ET 7/25/2023. ARV OF ALL PRIZES: $19,950. ODDS OF WINNING DEPEND ON THE TOTAL NUMBER OF ENTRIES RECEIVED. SUBJECT TO FULL OFFICIAL RULES. SPONSOR: MOE’S FRANCHISOR SPV LLC.",
    disclaimerText: "",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        imageContentURL: "/sweepsmoes/music-tickets.png",
        imageContentAlt: "VIP Tickets for a music festival",
        title: "Grand Prize (1)",
        description:
          "One grand prize winner will win a VIP Experience for 2 to a music festival of their choice",
      },
      {
        imageURL: "",
        imageAlt: "",
        imageContentURL: "/sweepsmoes/gift-cards.png",
        imageContentAlt: "Gift cards for a music concert",
        title: "First Place Prize (50)",
        description:
          "Fifty (50) first place prizes of music concert gift cards valued at $200",
      },
    ],
    showSpotlightSection: true,
    spotlightID: "download-app",
    spotlightTitle: "Moe Rewards App",
    spotlightDescription: [
      {
        text: "Download the Moe Rewards app today to redeem your 10% off reward with each order. Plus, use the app to track your points, order your favorites faster then ever and get exclusive offers from your favorite Moe's.",
      },
    ],
    spotlightImageURL: "/moes/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    showNoticesSection: false,
    notices1ImageURL: "",
    notices1ImageAlt: "",
    notices1Id: "",
    notices1Title: "",
    notices1Description: "",
    notices2Id: "",
    notices2ImageURL: "",
    notices2Title: "",
    notices2ImageAlt: "",
    notices2Description: "",
  },
};
